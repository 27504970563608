






























































































































































































































































































import { Component, Mixins } from 'vue-property-decorator';
import RegisterOrCreateCustomer from './RegisterOrCreateCustomer.vue';
import DialogMixin from '@/mixins/dialog';
import { projectModule } from '@/store/modules/project';
import { savedSearchModule } from '@/store/modules/savedSearch';
import SelectByObject from './SelectByObject.vue';
import { customerModule } from '@/store/modules/customer';

@Component({
    components: {
        RegisterOrCreateCustomer,
        SelectByObject
    }
})
export default class DialogSendEmail extends Mixins(DialogMixin) {
    form = {
        email: '',
        cellphone: ''
    };
    messageValidate = '';

    get sendWsp() {
        return projectModule.unselect.sendWsp;
    }

    set sendWsp(value: boolean) {
        projectModule.setUnselect({ sendWsp: value });
    }

    get sendEmail() {
        return projectModule.unselect.sendEmail;
    }

    set sendEmail(value: boolean) {
        projectModule.setUnselect({ sendEmail: value });
    }

    async store() {
        const response: any = await projectModule.sendEmail({
            idCliente: this.selectedCustomer._id,
            clientePrincipal: this.selectedCustomer.type === 'principal',
            email: this.selectedCustomer.email,
            unselect: projectModule.unselect
        });
        if (this.sendWsp == true) {
            const phone = `51${this.selectedCustomer.cellphone}`;
            const message = encodeURI(
                `Hola ${this.selectedCustomer.fullName} mira estos proyectos: ${response.link}`
            );
            const url_whatsap = `https://wa.me/${phone}?text=${message}`;
            const deviceAgent = navigator.userAgent;
            const ios = deviceAgent.toLowerCase().match(/(iphone|ipod|ipad)/);
            if (ios) {
                window.location.href = url_whatsap;
            } else {
                window.open(url_whatsap, '_blank');
            }
        }
        this.active++;
    }

    get showEmailInput() {
        return (
            this.isASavedSearch &&
            this.selectedCustomer &&
            !this.selectedCustomer.email
        );
    }

    async nextStepValidate() {
        try {
            if (!(this.sendWsp || this.sendEmail)) {
                this.messageValidate = 'Debe elegir una opción';
                return;
            } else {
                this.messageValidate = '';
            }

            await customerModule.update({
                _id: this.selectedCustomer._id,
                cellphone: this.selectedCustomer.cellphone
            });
            
            this.active++;
        } catch (error) {
            this.$notify.error({
                title: 'Error',
                message: error.mensaje
            });
        }
    }

    async nextFirstSetp() {
        try {
            if (this.type === 2) {
                this.registerOrCreate.sendData();
            } else if (this.showEmailInput) {
                if (this.selectedCustomer.tipoCliente === 'principal') {
                    await customerModule.update({
                        _id: this.selectedCustomer._id,
                        email: this.form.email
                    });
                } else {
                    await customerModule.updateAssociated({
                        _id: this.selectedCustomer._id,
                        email: this.form.email
                    });
                }
                savedSearchModule.setCustomers({
                    _id: this.selectedCustomer._id,
                    email: this.form.email
                });
                this.active++;
            } else {
                this.active++;
            }
        } catch (error) {
            this.$notify.error({
                title: 'Error',
                message: error.mensaje
            });
        }
    }

    get total() {
        return projectModule.projects.total;
    }

    get filters() {
        return projectModule.filter;
    }

    close(key: any) {
        projectModule.closeOrOpenDialog(key);
    }

    destroyed() {
        projectModule.setUnselect({
            projectName: true,
            address: true,
            sendWsp: true,
            sendEmail: true
        });
        projectModule.clearSelectedProjects();
        projectModule.activeOrDisabledChecked(false);
    }
}
